var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center align-items-md-center"},[_c('div',{staticClass:"col-md-11"},[_c('div',{staticClass:"card full-height bg-ligth"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row justify-content-center align-items-md-center"},[_c('div',{staticClass:"col-md-12"},[_c('b-table',{staticClass:"\n                table\n                table-responsive\n                table-head-custom\n                table-vertical-center\n                table-head-bg\n                table-borderless\n              ",attrs:{"fields":[
              'sala',
              'medico',
              'especialidade',
              'descricao',
              'status',
              'acoes' ],"items":_vm.lista_vinculo_especialidade,"per-page":_vm.perPage,"current-page":_vm.currentPage,"id":"sala-table","show-empty":"","empty-text":"Nenhum registro encontrado!"},scopedSlots:_vm._u([{key:"head(acoes)",fn:function(){return [_vm._v("Opciones")]},proxy:true},{key:"head(descricao)",fn:function(){return [_vm._v("Descripción")]},proxy:true},{key:"head(especialidade)",fn:function(){return [_vm._v("Especialidad")]},proxy:true},{key:"cell(status)",fn:function(ref){
            var item = ref.item;
return [_c('span'),_c('span',{staticClass:"label label-lg label-inline",class:{
                  'label-light-success': item.status === 1,
                  'label-light-warning': item.status === 2,

                },staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.status === 1 ? "Activo" : item.status === 2 ? "Inactivo" : "")+" ")])]}},{key:"cell(acoes)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-right w-100"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.lock_Sala),expression:"lista_permissoes_filial.lock_Sala"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Activar/Desactivar vínculo (al desactivar un vinculo, el deja de aparecer en las lista de consultorios disponibles para crear atendimientos)"},on:{"click":function($event){return _vm.confirm(item, 'La accion activa/inactiva esta opción en atendimentos')}}},[(item.status == 1)?_c('i',{staticClass:"fas fa-unlock-alt text-info"}):_c('i',{staticClass:"fas fa-lock text-info"})]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.u_Sala),expression:"lista_permissoes_filial.u_Sala"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Editar registro"},on:{"click":function($event){return _vm.atualizar(item)}}},[_c('i',{staticClass:"far fa-edit text-primary"})]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.lock_Sala),expression:"lista_permissoes_filial.lock_Sala"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Excluir registro"},on:{"click":function($event){return _vm.confirm(
                      item,
                      'La accion eliminara permanentemente este vinculo del sistema'
                    )}}},[_c('i',{staticClass:"fas fa-trash text-danger"})])])]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.lista_vinculo_especialidade.length,"per-page":_vm.perPage,"aria-controls":"sala-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Especialidades y Doctores por Salas")])])}]

export { render, staticRenderFns }