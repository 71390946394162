<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Especialidades y Doctores por Salas</h3>
        </div>
        <div class="card-body">
          <div class="row justify-content-center align-items-md-center">
            <div class="col-md-12">
              <b-table :fields="[
                'sala',
                'medico',
                'especialidade',
                'descricao',
                'status',
                'acoes',
              ]" :items="lista_vinculo_especialidade" :per-page="perPage" :current-page="currentPage" id="sala-table"
                class="
                  table
                  table-responsive
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-borderless
                " show-empty empty-text="Nenhum registro encontrado!">
                <template #head(acoes)>Opciones</template>
                <template #head(descricao)>Descripción</template>
                <template #head(especialidade)>Especialidad</template>
                <template #cell(status)="{ item }"><span></span>
                  <span style="font-size: 12px" class="label label-lg label-inline" :class="{
                    'label-light-success': item.status === 1,
                    'label-light-warning': item.status === 2,

                  }">
                    {{
                      item.status === 1
                        ? "Activo"
                        : item.status === 2
                          ? "Inactivo"
                          : ""
                    }}
                  </span>
                </template>
                <template #cell(acoes)="{ item }">
                  <div class="text-right w-100">

                    <button v-show="lista_permissoes_filial.lock_Sala"
                      @click="confirm(item, 'La accion activa/inactiva esta opción en atendimentos')"
                      class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover
                      title="Activar/Desactivar vínculo (al desactivar un vinculo, el deja de aparecer en las lista de consultorios disponibles para crear atendimientos)">
                      <i v-if="item.status == 1" class="fas fa-unlock-alt text-info"></i>
                      <i v-else class="fas fa-lock text-info"></i>
                    </button>

                    <button v-show="lista_permissoes_filial.u_Sala" @click="atualizar(item)"
                      class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Editar registro">
                      <i class="far fa-edit text-primary"></i>
                    </button>

                    <button v-show="lista_permissoes_filial.lock_Sala" @click="
                      confirm(
                        item,
                        'La accion eliminara permanentemente este vinculo del sistema'
                      )
                      " class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Excluir registro">
                      <i class="fas fa-trash text-danger">
                      </i>
                    </button>

                  </div>
                </template>
              </b-table>
              <b-pagination v-model="currentPage" :total-rows="lista_vinculo_especialidade.length" :per-page="perPage"
                aria-controls="sala-table">
              </b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Vinculos Salas" }]);
  },
  created() {
    this.listar_sala();
  },
  computed: {
    lista_vinculo_especialidade() {
      return this.$store.state.configEmpresa.lista_vinculo_especialidade;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
  },
  methods: {
    async listar_sala() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);

      await this.$store
        .dispatch("configEmpresa/list_vinculo_sala_especialidade")
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("configEmpresa/atualizar", "");
        });
    },
    atualizar(value) {
      this.$router.push({ name: "create_vinculo_sala" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value, msg) {
      await this.$confirm({
        title: "Estas seguro?",
        message: msg,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (msg == 'La accion activa/inactiva esta opción en atendimentos') this.bloquear(value);
            if (msg == 'La accion eliminara permanentemente este vinculo del sistema') this.excluir(value);
          }
        },
      });
    },
    async excluir(value) {
      await this.$store.dispatch(
        "configEmpresa/excluir_vinculo_especialidade",
        value.id
      );
      this.listar_sala()
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    async bloquear(item) {
      let status = 1;
      if (item.status == 1) {
        status = 2
      } else if (item.status == 2) {
        status = 1
      }
      var dados = {
        status: status,
        id: item.id
      }
      await this.$store.dispatch(
        "configEmpresa/update_vinculo_sala_especialidade", dados
      );
      this.listar_sala()
      console.log(this.alertMessage)
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>